import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'

const EmberConsultingPage = () => {
  return (
    <Layout>
      <div className="page-section content container">
        <Head title="Ember.js Consulting" />
        <h1 className="has-text-danger">Ember.js Consulting</h1>
        <p>I am often available to help out with Ember.js projects. I am not an agency, so I don't have staff, but I can take on part time or full time commitments depending on what's currently in my queue. I have been working with Ember.js since 2014 and am passionate about developing beautiful user experiences.</p>

        Areas of expertise include:

        <ul>
          <li>CSS and layouts</li>
          <li>Admin interfaces and Forms</li>
          <li>Data Visualizations</li>
          <li>Automated Tests</li>
          <li>Animations</li>
          <li>QUnit</li>
          <li>Mirage</li>
          <li>Ember Octane</li>
          <li>ember-concurrency</li>
          <li>ember-data</li>
        </ul>
        
        <p>Feel free to reach out if you could use a hand on your Ember project. I can show examples of my work that are relevent to your app. You can reach me at robustdj@gmail.com</p>
      </div>
    </Layout>
  )
}

export default EmberConsultingPage
